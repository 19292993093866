// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
@import url(https://fonts.googleapis.com/css?family=Anton);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/card-js/card-js.min";

.form-wrapper {
  position: relative;
}

.page-header {
  background: #000;
  color: #FFF;
  margin: 0 0 22px;
}

.header-img {
  padding:10px;
}

h1 {
  margin:0px;
}

.donate {
  overflow: hidden;
}

.form-wrapper {
  width:400%;
  margin-left:0
}

.form-group {
  padding: 0 5px;
}

input.parsley-error {
  border:1px solid #FF0000;
}

ul.parsley-errors-list li {
  color: #FF0000;
}

input[type="checkbox"].tsandcs {
  margin: 0 5px;
}
